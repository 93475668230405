<template>
  <div id="app">
    <Header/>
    <Products/>
    <Delivery/>
    <Contact/>
  </div>
</template>

<script>
import Header from './components/Header'
import Products from './components/Products'
import Delivery from './components/Delivery'
import Contact from './components/Contact'

export default {
  name: 'App',
  components: {
    Header,
    Products,
    Delivery,
    Contact
  }
}
</script>

<style>
._text-shadow {
  text-shadow: 0 0 2px rgba(30, 25, 22, 0.75);
}
</style>